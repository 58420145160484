import { useState, useEffect } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { Heading, Input, Select, Text, Textarea } from "@equidefi/ui";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MESSAGE } from "../../../issuer/src/constants/forms";
import { isValidPhoneNumber } from "react-phone-number-input";
import { PhoneField } from "@equidefi/portals/components/Auth";
import NavBar from "../components/nav/NavBar";
import { useUserInvestments } from "../hooks/useInvestments";
import {
  useIntercomGetTicketTypes,
  useIntercomCreateTicket,
} from "../hooks/useIntercomSupport";
import { userFullName } from "@equidefi/shared";
import { Link } from "react-router-dom";
import SupportPageSuccess from "./SupportPageSuccess";

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required(MESSAGE.REQUIRED("Email")),
  name: Yup.string()
    .trim()
    .nullable()
    .min(2, "First name must be at least 2 characters")
    .required(MESSAGE.REQUIRED("Name")),
  phone: Yup.string()
    .nullable()
    .test({
      name: "phone",
      message: "Must be a valid phone number",
      test: (value) => (!!value ? isValidPhoneNumber(value) : true),
    }),
  offering_id: Yup.string().nullable(),
  investment_amount: Yup.number().nullable(),
  ticket_type_id: Yup.string().required(MESSAGE.REQUIRED("Topic")),
  description: Yup.string().nullable().required(MESSAGE.REQUIRED("Question")),
});

export default function SupportPage() {
  const { data: user } = useCurrentUser();
  const { data: investments } = useUserInvestments();

  const { data: ticketTypes } = useIntercomGetTicketTypes();
  const intercomTickets = useIntercomCreateTicket();
  const toast = useToast();

  const topicOptions = ticketTypes?.map((ticket) => ({
    label: ticket.name,
    value: ticket.id,
  }));

  const investmentOptions = investments?.map((investment) => {
    return {
      label: investment.offering.name,
      value: investment.offering.id,
      amount: investment.amount,
    };
  });

  const [selectedInvestment, setSelectedInvestment] = useState();
  const [submittedTicket, setSubmittedTicket] = useState(false);

  useEffect(() => {
    if (investmentOptions?.length === 1 && !selectedInvestment) {
      setSelectedInvestment(investmentOptions[0]);
    }
  }, [investmentOptions, selectedInvestment]);

  const onSubmit = async (data) => {
    try {
      await intercomTickets.mutateAsync(data);
      resetForm();
      if (investmentOptions?.length > 1) {
        setSelectedInvestment();
      }
      setSubmittedTicket(true);
    } catch (e) {
      console.error(e);
      toast({
        status: "error",
        description: e.response.data.errors.join(". "),
      });
    }
  };

  const initialValues = {
    name: userFullName(user),
    email: user?.email,
    phone: user?.phone,
    offering_id: investmentOptions?.[0]?.value,
    investment_amount: investmentOptions?.[0]?.amount,
    description: "",
    ticket_type_id: "",
  };

  const {
    handleSubmit,
    setFieldValue,
    handleBlur,
    getFieldProps,
    errors,
    touched,
    isValid,
    resetForm,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  return (
    <Stack
      as="main"
      bgColor="background"
      direction={{ base: "column", lg: "row" }}
    >
      <NavBar>
        <Flex
          direction="column"
          align="center"
          justify={{ base: "start", lg: "center" }}
          w="full"
          minH="100vh"
        >
          {submittedTicket ? (
            <SupportPageSuccess />
          ) : (
            <Box
              maxWidth="560px"
              w="full"
              p={4}
              as="form"
              onSubmit={handleSubmit}
            >
              <Heading fontWeight="bold" textStyle="h1">
                Submit a Support Ticket
              </Heading>
              <Input
                label="Email"
                error={errors?.email}
                isInvalid={errors?.email && touched?.email}
                isRequired
                placeholder="john@smith.com"
                {...getFieldProps("email")}
              />
              <Input
                label="Name"
                placeholder="John Smith"
                isRequired
                error={touched?.name && errors?.name}
                isInvalid={errors?.name && touched?.name}
                {...getFieldProps("name")}
              />

              <PhoneField
                label="Phone"
                handleBlur={handleBlur}
                error={touched?.phone && errors?.phone}
                setFieldValue={setFieldValue}
                isRequired={false}
                {...getFieldProps("phone")}
              />
              {user ? (
                <Select
                  autoFocus={false}
                  label={"Select your investment"}
                  onChange={(value) => {
                    setFieldValue("offering_id", value?.value);
                    setFieldValue("investment_amount", value?.amount);
                    setSelectedInvestment(value);
                  }}
                  value={selectedInvestment}
                  defaultValue={selectedInvestment}
                  isRequired
                  options={investmentOptions}
                  components={{
                    IndicatorSeparator: null,
                  }}
                  name="offering_id"
                  mb={2}
                />
              ) : (
                <Alert borderRadius="lg" my="4">
                  <AlertIcon />
                  <AlertDescription>
                    <Text
                      as={Link}
                      _hover={{ color: "blue.600" }}
                      color="blue.500"
                      textStyle="body"
                      textDecor="underline"
                      to="/"
                      state={{ redirectURL: "/support" }}
                    >
                      Sign in
                    </Text>{" "}
                    to include your investment data
                  </AlertDescription>
                </Alert>
              )}

              <Select
                autoFocus={false}
                label={"Select a topic..."}
                onChange={({ value }) => {
                  setFieldValue("ticket_type_id", value);
                }}
                options={topicOptions}
                isRequired
                components={{
                  IndicatorSeparator: null,
                }}
                name="topic"
                mb={2}
              />
              <Textarea
                label="Please type your question here:"
                type="textarea"
                isRequired
                error={touched?.description && errors?.description}
                isInvalid={errors?.description && touched?.description}
                {...getFieldProps("description")}
              />
              <Button
                w="full"
                mb="4"
                type="submit"
                isDisabled={!isValid}
                isLoading={intercomTickets.isLoading}
              >
                Submit ticket
              </Button>
            </Box>
          )}
        </Flex>
      </NavBar>
    </Stack>
  );
}
