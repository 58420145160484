import { Box, ButtonGroup, Flex } from "@chakra-ui/react";
import { Heading } from "@equidefi/ui";
import { WorkflowButton } from "../../components/workflow/WorkflowButton";

export const WorkflowContainer = ({
  children,
  title,
  onContinue,
  buttonLabel = "Continue",
  buttonProps,
  ...rest
}) => {
  return (
    <Flex
      direction="column"
      align="center"
      justify={{ base: "start", lg: "center" }}
      w="full"
      minH="full"
    >
      <Box maxWidth="560px" w="full" p={{ base: 4, lg: 0 }} {...rest}>
        {title && (
          <Heading textStyle="h1" m={0} fontWeight="700">
            {title}
          </Heading>
        )}
        {children}

        {onContinue && (
          <ButtonGroup gap={2} w="full">
            <WorkflowButton
              onClick={onContinue}
              mb={{ base: 0, lg: 4 }}
              {...buttonProps}
            >
              {buttonLabel}
            </WorkflowButton>
          </ButtonGroup>
        )}
      </Box>
    </Flex>
  );
};
