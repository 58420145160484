import { useMutation, useQuery } from "@tanstack/react-query";
import InquiryClient from "@equidefi/portals/clients/InquiryClient";
import { useApi } from "@equidefi/portals/hooks/useApi";

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useInquiry = (id, options = {}) => {
  const inquiryApi = useApi(InquiryClient);

  return useQuery(["inquiry", id], () => inquiryApi.find(id), {
    enabled: !!id,
    ...options,
  });
};

export const useResumeInquiry = (options = {}) => {
  const api = useApi(InquiryClient);

  return useMutation(async ({ inquiryId }) => api.resume(inquiryId), options);
};
