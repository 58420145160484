import { FormControl, FormErrorMessage, useBoolean } from "@chakra-ui/react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useContext, useState } from "react";

import { useApi } from "@equidefi/portals/hooks/useApi";

import StripeClient from "../clients/StripeClient";
import { CONFIG } from "../constants/config";
import { WorkflowContext } from "../pages/workflow/context";
import Spinner from "./spinner";
import { WorkflowButton } from "./workflow/WorkflowButton";

const baseUrl = new URL(CONFIG.URL);

const StripePaymentForm = ({
  investmentId,
  paymentIntentId,
  returnPath = "/payment/processing",
}) => {
  const { analytics } = useContext(WorkflowContext);

  const returnUrl = new URL(returnPath, baseUrl);

  const [isValid, setIsValid] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isLoading, { on: startLoading, off: stopLoading }] = useBoolean();

  const stripeApi = useApi(StripeClient);

  const handleOnReady = () => {
    setIsReady(true);
  };

  /** @param {import("@stripe/stripe-js").StripeElementChangeEvent} data */
  const handleOnChange = (data) => {
    stripeApi.togglePaymentIntentType(
      investmentId,
      paymentIntentId,
      data.value.type
    );
    setIsValid(data.complete);
  };

  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    analytics.track("payment", {
      type: "stripe",
    });

    startLoading();

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: returnUrl.toString() },
    });

    if (error) setErrorMessage(error.message);

    stopLoading();
  };

  return (
    <form onSubmit={handleSubmit}>
      {!isReady && <Spinner show />}
      <PaymentElement onReady={handleOnReady} onChange={handleOnChange} />

      <FormControl isInvalid={!!errorMessage}>
        {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
      </FormControl>

      <WorkflowButton
        type="submit"
        mt="4"
        isLoading={isLoading}
        isDisabled={!isValid}
      >
        Pay
      </WorkflowButton>
    </form>
  );
};

export default StripePaymentForm;
