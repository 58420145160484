import { useContext } from "react";
import { WorkflowContext } from "../context";
import Register from "./Register";
import useWorkflowPosition from "../../../hooks/useWorkflowPosition";
import { useNavigate } from "react-router-dom";
import OfferingError from "../../../components/offerings/OfferingError";
import { offeringIsActive } from "@equidefi/shared";

const WorkflowRegistration = (props) => {
  const { isLoggedIn, offering } = useContext(WorkflowContext);
  useWorkflowPosition(1);
  const navigate = useNavigate();

  if (offering?.status && !offeringIsActive(offering)) {
    return (
      <OfferingError
        title="Offering Not Available"
        message={`Sorry but you cannot invest in this offering, yet.`}
      />
    );
  }

  if (isLoggedIn) {
    navigate(`/offerings/${offering.slug}/profile`);
  }

  return <Register offering={offering} history={props.history} />;
};

export default WorkflowRegistration;
