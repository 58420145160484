import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { useEffect, useState } from "react";
import OfferingClient from "@equidefi/portals/clients/OfferingClient";
import { useCurrentInvestment } from "./useInvestments";
import { useOfferingAnalytics } from "./useOfferingAnalytics";
import { useApi } from "@equidefi/portals/hooks/useApi";

export function useWorkflowStartup(slug, complete) {
  const [offering, setOffering] = useState(null);
  const [user, setUser] = useState(null);
  const [investment, setInvestment] = useState(null);
  const [startUri, setStartUri] = useState(null);
  const [isError, setError] = useState(false);

  const analytics = useOfferingAnalytics(offering);

  const { data: currentInvestment, refetch } = useCurrentInvestment(slug, {
    refetchOnMount: true,
    cacheTime: 0,
  });

  const { data: me } = useCurrentUser();
  const offeringApi = useApi(OfferingClient);

  useEffect(() => {
    setUser(me);
  }, [me]);

  useEffect(() => {
    const run = async () => {
      // Load offering, user, and investment in parallel
      const offering = await offeringApi
        .getById(slug)
        .then()
        .catch((error) => {
          console.log(error);
          setError(true);
        });

      const investment = currentInvestment || (await refetch()).data;

      setOffering(offering);
      setInvestment(investment);

      let next = investment?.next;

      // Don't try to route to these pages, they don't exist for the investor.
      if (
        next === "DILIGENCE" ||
        next === "COUNTERSIGN" ||
        next === "REFUND" ||
        next === "CLOSING"
      ) {
        setStartUri("complete");
        return;
      }

      // Determine initial uri to load
      if (next) {
        setStartUri(investment.next.toLowerCase());
      } else {
        setStartUri("registration");
      }
    };

    if (!complete) {
      run().then();
    }
  }, [complete]);

  return {
    offering,
    user,
    setUser,
    investment,
    setInvestment,
    startUri,
    isError,
    analytics,
  };
}
