import { useContext } from "react";

import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import useWorkflowPosition from "../../../hooks/useWorkflowPosition";
import { WorkflowContext } from "../context";
import InvestmentAgreements from "./InvestmentAgreements";

const WorkflowAgreements = () => {
  const { investment } = useContext(WorkflowContext);
  useWorkflowPosition(7);

  useDocumentTitle([
    "Subscription Agreement",
    investment?.offering?.name,
    investment?.issuer?.name,
  ]);

  return (
    <InvestmentAgreements
      investment={investment}
      investmentAgreements={investment?.investment_agreements}
    />
  );
};

export default WorkflowAgreements;
