export const investmentContinueUrl = (
  investmentId,
  next,
  offeringSlug,
  status
) => {
  if (status !== "PENDING") return null;

  let nextUri;
  // If no investmentId but offeringId is set, navigate to start screen
  if (!investmentId && offeringSlug) nextUri = "registration";

  if (
    next === "DILIGENCE" ||
    next === "COUNTERSIGN" ||
    next === "REFUND" ||
    next === "CLOSING"
  ) {
    nextUri = "complete";
  }

  // Determine initial uri to load
  if (next) {
    nextUri = next.toLowerCase();
  } else {
    nextUri = "registration";
  }

  return `/offerings/${offeringSlug}/${nextUri}`;
};
