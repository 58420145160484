import { Box, Flex, IconButton, Tooltip } from "@chakra-ui/react";
import { DownloadIconButton } from "@equidefi/portals/components/investments/DownloadButton";
import { Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";

const AgreementsItem = ({
  key,
  iconCondition,
  title,
  buttonAction,
  button = null,
  tooltip,
  isDisabled,
  isLoading,
}) => {
  const IconComponent = iconCondition ? Icon.CheckCircle : Icon.AlertTriangle;

  const Button = button ? (
    button
  ) : (
    <IconButton
      color="equidefi.blue"
      bg="none"
      icon={<Icon.Download size="1.5em" />}
      onClick={buttonAction}
      isDisabled={isDisabled}
      isLoading={isLoading}
    />
  );

  return (
    <Box
      w="full"
      py={2}
      borderBottom="1px"
      borderColor="gray.200"
      _last={{ border: "unset", pb: 0 }}
      key={key}
    >
      <Tooltip
        isDisabled={!tooltip}
        label={tooltip}
        aria-label={tooltip}
        hasArrow
      >
        <Flex align="center">
          <IconComponent size="1em" />
          <Text maxW="60%" mb="0" ml={2} mr="auto">
            {title}
          </Text>
          {Button}
        </Flex>
      </Tooltip>
    </Box>
  );
};

const OfferingAgreementsList = ({
  isLoading: spinner = false,
  investment,
  data,
  onPrintNda = () => {},
  onDownloadPaymentConfirmation = () => {},
}) => {
  const { summary } = data ?? {};

  const paymentReceived =
    investment?.payments?.length || investment?.stripe_payments?.length;

  return (
    <>
      {data?.offering_agreement?.name && (
        <AgreementsItem
          agreementSigned={investment?.investor?.name}
          title={data?.offering_agreement?.name}
          buttonAction={onPrintNda}
          button={
            <DownloadIconButton
              investmentId={investment?.id}
              isDisabled={!summary?.isCountersigned}
            />
          }
        />
      )}

      <AgreementsItem
        key={"Payment Confirmation"}
        agreementSigned={paymentReceived}
        title={"Payment Confirmation"}
        buttonAction={onDownloadPaymentConfirmation}
        tooltip={
          !paymentReceived &&
          "A receipt for bank wire or check payment will be available once payment has been received."
        }
        isDisabled={!paymentReceived}
        isLoading={spinner}
      />
    </>
  );
};

export default OfferingAgreementsList;
