import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/spinner";
import { useEventDispatcher } from "../../../hooks/useInvestments";
import { WorkflowContainer } from "../WorkflowContainer";
import { WorkflowContext } from "../context";

const WorkflowPaymentProcessing = () => {
  const { investment, offering, user } = useContext(WorkflowContext);
  const navigate = useNavigate();
  const dispatchEvent = useEventDispatcher();

  useEffect(() => {
    (async () => {
      await dispatchEvent.mutateAsync({
        event: "PAYMENT",
        action: "SUBMITTED",
        investmentId: investment.id,
        actor: { user: user.id },
        meta: {
          type: "ach",
        },
      });

      navigate(`/offerings/${offering.slug}/address`);
    })();
  }, []);

  return (
    <WorkflowContainer title="Payment Complete">
      <Spinner show />
    </WorkflowContainer>
  );
};

export default WorkflowPaymentProcessing;
