import { ApiClient } from "@equidefi/portals/clients";
import UserToken from "@equidefi/portals/clients/UserToken";
import { ApiInstance } from "./api";

class InvestmentClient extends ApiClient {
  /**
   * @param {string?} offeringId
   * @return {Promise<object>}
   */
  async currentInvestment(offeringId) {
    const token = UserToken.token();
    if (!token || !offeringId) return null;

    const response = await this.get(`/v2/investments/current`, {
      params: { offering_id: offeringId },
    });

    return response.data;
  }

  static async acceptTos(id) {
    const response = await ApiInstance.post(`/investment/${id}/tos`);
    return response.data;
  }

  /**
   * @param {string} id
   * @param {string} stage
   * @param {object} data
   * @return {Promise<object>}
   */
  static async updateStage(id, stage, data = {}) {
    const response = await ApiInstance.patch(
      `/v2/investments/${id}/stage/${stage}`,
      data
    );
    return response.data;
  }

  /**
   * @param {string} offeringId
   * @param {string} bcode - Broker tracking code
   * @return {Promise<object>}
   */
  static async createInvestment(offeringId, bcode) {
    const response = await ApiInstance.post(`/v2/investments/`, {
      offering_id: offeringId,
      bcode: bcode,
    });
    return response.data;
  }

  static async getAgreementDocusignUrl(id, envelopeId, type) {
    const response = await ApiInstance.get(
      `/investment/${id}/agreement/${envelopeId}/url/${type}`
    );
    return response.data;
  }

  static async legacyCreatePaymentIntent(id) {
    const response = await ApiInstance.post(`/investment/${id}/payment-intent`);
    return response.data;
  }

  static async legacyCreateEnvelope(id, templateId) {
    const response = await ApiInstance.post(
      `/investment/${id}/agreement/${templateId}`
    );
    return response.data;
  }

  static async resetInquiry(investmentId, inquiryId) {
    await ApiInstance.delete(
      `/v2/investments/${investmentId}/inquiry/${inquiryId}`
    );
  }

  static async resumeInquiry(investmentId, inquiryId) {
    await ApiInstance.post(
      `/v2/investments/${investmentId}/inquiry/${inquiryId}`
    );
  }

  static async subscription(
    investmentId,
    amount,
    quantity,
    annualIncome,
    accredited = null
  ) {
    const response = await ApiInstance.post(
      `/v2/investments/${investmentId}/subscription`,
      {
        amount: amount,
        quantity: quantity,
        annual_income: annualIncome,
        accredited,
      }
    );

    return response.data;
  }

  static async dispatchEvent(investmentId, payload) {
    const response = await ApiInstance.post(
      `/v2/investments/${investmentId}/event`,
      payload
    );

    return response.data;
  }
}

export default InvestmentClient;
