import {
  MANUAL_PAYMENT_PENDING,
  PAYMENT_FAILED,
  PAYMENT_PENDING,
  PAYMENT_PROCESSING,
  PAYMENT_SUCCESSFUL,
} from "@equidefi/shared/constants/investments";
import { Box, VStack } from "@chakra-ui/react";
import moment from "moment";
import React from "react";

import NumberFormat from "react-number-format";
import InvestmentDisplayItem from "./InvestmentDisplayItem";
import { Text } from "@equidefi/ui";

const PAYMENT_STATUS_DISPLAY = {
  created: PAYMENT_PENDING,
  processing: PAYMENT_PROCESSING,
  succeeded: PAYMENT_SUCCESSFUL,
  manual_payment_pending: MANUAL_PAYMENT_PENDING,
  manual_payment_succeeded: "Manual Payment",
  manual_refund_succeeded: "Manual Refund",
  payment_failed: PAYMENT_FAILED,
};

const PaymentStatus = ({ status }) => {
  const text = PAYMENT_STATUS_DISPLAY[status];

  if (!text) {
    return null;
  }

  return (
    <Text mb="1" textStyle="context">
      {text}
    </Text>
  );
};

const PaymentsCard = ({ payments, stripePayments }) => {
  return (
    <>
      {payments?.map((payment, index) => {
        return (
          <InvestmentDisplayItem
            label="Manual Payment"
            helperText={
              <VStack>
                <PaymentStatus status={payment.status} />
                <Text m="0" textStyle="context">
                  {payment.create_date &&
                    moment(payment.create_date).format("lll Z")}
                </Text>
              </VStack>
            }
            value={
              <NumberFormat
                value={payment.amount}
                displayType="text"
                thousandSeparator={true}
                prefix="$"
              />
            }
          />
        );
      })}
      {stripePayments?.map((payment, index) => {
        return (
          <InvestmentDisplayItem
            label="Credit Card Payment"
            helperText={
              <Box>
                <PaymentStatus status={payment.status} />
                <Text m="0" textStyle="context">
                  {payment.create_date &&
                    moment(payment.create_date).format("lll Z")}
                </Text>
              </Box>
            }
            value={
              <NumberFormat
                value={payment.amount}
                displayType="text"
                thousandSeparator={true}
                prefix="$"
              />
            }
          />
        );
      })}
    </>
  );
};

export default PaymentsCard;
