import { useToast } from "@chakra-ui/react";
import UserToken from "@equidefi/portals/clients/UserToken";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { WorkflowContext } from "../pages/workflow/context";
import { useCurrentUser, useRegisteredUser } from "./useUsers";
import { useOfferingAuth, useOfferingRegister } from "./useOfferings";
import { useInvestmentCreate } from "./useInvestments";
import { useApi } from "@equidefi/portals/hooks/useApi";
import InvestmentClient from "../clients/InvestmentClient";
import {
  useLogin,
  useMFACode,
} from "@equidefi/portals/hooks/useAuthentication";
import { useCookies } from "react-cookie";

export const useWorkflowRegister = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [cookies] = useCookies(["bcode"]);

  const COMPLETED_STATUSES = ["DILIGENCE", "COUNTERSIGN", "REFUND", "CLOSING"];
  const email = useSelector((state) => state.email);

  const {
    setLoggedIn,
    investment,
    setInvestment,
    setUser,
    analytics,
    offering,
  } = useContext(WorkflowContext);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const createInvestment = useInvestmentCreate();
  const investmentApi = useApi(InvestmentClient);
  const registerUser = useOfferingRegister(offering.id);

  const confirmMFA = useMFACode({
    onSettled: () => setIsLoading(false),
    onSuccess: async (data) => {
      await startInvestment(data.user, data.token);
    },
    onError: () => {
      toast({
        status: "error",
        description: "Incorrect code, please try again",
      });
    },
  });

  const onSubmitRegistration = async (data) => {
    setIsLoading(true);
    const time = new Date();

    try {
      const response = await registerUser.mutateAsync({
        email: data.email,
        password: data.password,
        bcode: `${cookies.bcode}`,
        accept_tos: time,
        accept_privacy: time,
        type: "investor",
      });

      if (response.status === 201) {
        UserToken.setToken(response.data.token);
        setUser(response.data.user);
        setInvestment(response.data.investment);
        dispatch({ type: "email", payload: data.email });
        dispatch({ type: "user", payload: response.data.user });
        dispatch({ type: "token", payload: response.data.token });

        toast({
          status: "success",
          description: "Your investment was created, let's get started!",
        });
      } else if (response.status === 200) {
        dispatch({ type: "email", payload: data.email });
        navigate(`/offerings/${offering.slug}/mfa`, { state: data });
        return "register";
      }
    } catch (error) {
      let errorMessage = "There was an error trying to register your account.";
      if (error.response.status === 401) {
        errorMessage = "Login failed, try again or reset your password";
        setShowForgotPassword(true);
      }
      if (error.response.status === 403) {
        errorMessage = "You are not allowed to access this portal";
      }
      toast({
        status: "error",
        description: errorMessage,
      });
    }

    setIsLoading(false);
  };

  const onAuthenticate = async (email, password) => {};

  const onSubmitMFA = async (email, code) => {
    setIsLoading(true);

    await confirmMFA.mutateAsync({
      email: email,
      mfa_code: code,
    });
  };

  const isCompleted = (investment) => {
    if (!investment?.next) {
      return false;
    }

    return COMPLETED_STATUSES.includes(investment.next);
  };

  const onInvestmentCreate = async () => {
    const investment = await createInvestment.mutateAsync({
      offeringId: offering.id,
      bcode: cookies.bcode,
    });

    toast({
      status: "success",
      description: "Your investment was created, let's get started!",
    });

    return investment;
  };

  const onCurrentInvestment = (investment) => {
    let message;
    if (isCompleted(investment)) {
      message = "Your investment is already completed.";
    } else {
      message =
        "We found your investment, we'll get you back to your last place.";
    }

    toast({
      status: "success",
      description: message,
    });

    return investment;
  };

  const startInvestment = async (user, token) => {
    try {
      analytics.track("registered");
      dispatch({ type: "token", payload: token });
      dispatch({ type: "email", payload: undefined });
      setUser(user);
      UserToken.setToken(token);

      const existingInvestment = await investmentApi.currentInvestment(
        offering.id
      );
      const investment = existingInvestment
        ? onCurrentInvestment(existingInvestment)
        : await onInvestmentCreate();

      setInvestment(investment);
    } catch (error) {
      toast({
        status: "error",
        description:
          "There was an error trying to create your initial investment.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (investment?.next) {
      // Redirect to dashboard when the investment is completed
      if (isCompleted(investment)) {
        navigate("/vault/dashboard");
      } else {
        // Determine initial uri to load
        navigate(
          `/offerings/${offering.slug}/${investment.next.toLowerCase()}`
        );
      }
    }
  }, [investment, navigate, setLoggedIn, offering]);

  return {
    isLoading,
    showForgotPassword,
    email,
    onSubmitRegistration,
    onSubmitMFA,
  };
};
