import { Stack } from "@chakra-ui/react";
import { gsap } from "gsap";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import UserToken from "@equidefi/portals/clients/UserToken";
import { offeringIsCompleted } from "@equidefi/shared";
import { useCookies } from "react-cookie";
import {
  Outlet,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import NavBar from "../../components/nav/NavBar";
import { identify as identifyFullStory } from "../../helpers/fullstory";
import { identify as identifyIntercom } from "../../helpers/intercom";
import { useWorkflowStartup } from "../../hooks/useStartup";
import Logo from "../../images/logo.png";
import { QUOTES } from "./constants";
import { WorkflowContext } from "./context";
import styles from "./index.module.css";

const LoadingView = ({ isError }) => {
  const loadingBar = useRef();
  const loader = useRef();
  const selectedIndex = Math.round(Math.random() * 5);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.from(loadingBar.current, {
        width: 0,
        duration: 1.85,
        ease: "circ.out",
        delay: 0.5,
      });
    }, loader);

    return () => ctx.revert();
  }, []);

  if (isError) {
    return (
      <div className={styles.loading}>
        <div className={styles.logo}>
          <img src={Logo} alt="EquiDeFi" />
        </div>
        <div className={styles.quoteText}>
          Failed to load the offering, please check your internet connection
        </div>
      </div>
    );
  }

  return (
    <div className={styles.loading} ref={loader}>
      <div className={styles.logo}>
        <img src={Logo} alt="EquiDeFi" />
      </div>
      <div className={styles.loadingBorder}>
        <div className={styles.loadingBar} ref={loadingBar} />
      </div>
      <div className={styles.quoteText}>
        <div className={styles.quote}>{QUOTES[selectedIndex].quote}</div>
        <div className={styles.line} />
        <div className={styles.person}>{QUOTES[selectedIndex].name}</div>
      </div>
    </div>
  );
};

const Workflow = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();
  const { state } = useNavigation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [complete, setComplete] = useState(false);
  const [cookies, setCookie] = useCookies(["bcode"]);
  const {
    user,
    setUser,
    investment,
    setInvestment,
    offering,
    startUri,
    isError,
    analytics,
  } = useWorkflowStartup(slug, complete);
  const [delay, setDelay] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      // Purely for visual purposes to simulate loading the offering
      setDelay(false);
    }, 2250);
  }, []);

  useEffect(() => {
    // MFA Code
    const code = searchParams.get("code");

    // Broker tracking code
    const bcode = searchParams.get("bcode");

    if (bcode) {
      setCookie("bcode", bcode, { path: "/", maxAge: 30758480 }); // set broker code cookie, expires in 1 year
    }

    if (user) {
      identifyFullStory(user);
    }

    if (offering?.slug && startUri && !complete && !code) {
      setComplete(true);

      if (!searchParams.get("payment_intent")) {
        navigate(`/offerings/${offering.slug}/${startUri}`);

        // Maintain any query params that are sent after navigating to startUri
        setSearchParams(searchParams);
      }
    }

    if (offeringIsCompleted(offering)) {
      navigate("/vault/dashboard");
    }
  }, [startUri, offering, complete, navigate]);

  const [isLoggedIn, setLoggedIn] = useState(!!UserToken.token());
  const [position, setPosition] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [position, location]);

  if (state === "loading" || delay || !offering) {
    return <LoadingView isError={isError} />;
  }

  let content = <Outlet />;

  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      as="main"
      minH="100vh"
      spacing={0}
      bgColor="background"
    >
      <WorkflowContext.Provider
        value={{
          isLoggedIn,
          setLoggedIn,
          position,
          setPosition,
          user,
          setUser,
          offering,
          investment,
          analytics,
          setInvestment,
        }}
      >
        <NavBar offering={offering} position={position}>
          {content}
        </NavBar>
      </WorkflowContext.Provider>
    </Stack>
  );
};

export default Workflow;
