import { useQuery } from "@tanstack/react-query";
import IssuerClient from "../clients/IssuerClient";

/**
 * @param {string} issuerId
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useIssuer = (issuerId, options = {}) =>
  useQuery(["issuer", issuerId], () => IssuerClient.legacyGet(issuerId), {
    enabled: !!issuerId,
    ...options,
  });
