import { useFormik } from "formik";
import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { useToast } from "@chakra-ui/react";
import {
  useCurrentUser,
  useUpdateCurrentUser,
} from "@equidefi/portals/hooks/useUsers";
import { useUpdateInvestment } from "@equidefi/portals/hooks/useInvestments";
import { Heading, Text } from "@equidefi/ui";
import RadioGroup from "@equidefi/ui/forms/RadioGroup";
import { useEventDispatcher } from "../../../hooks/useInvestments";
import useWorkflowPosition from "../../../hooks/useWorkflowPosition";
import { WorkflowContainer } from "../WorkflowContainer";
import { WorkflowContext } from "../context";
import { AddressForm } from "./AddressForm";
import { GooglePlaceSearch } from "./GooglePlaceSearch";

// Value for 'address_city' approximates max length: 29 chars per line.
// See https://developers.hellosign.com/docs/templates/walkthrough/#using-long-text-in-custom-fields"
const MAX_CHARACTERS = 29;

const addressSchema = Yup.object().shape({
  address_street_1: Yup.string().required("Please enter your street address"),
  address_street_2: Yup.string().nullable(),
  address_city: Yup.string()
    .required("Please enter your city")
    .max(MAX_CHARACTERS, "City name should not exceed 29 characters"),
  address_state: Yup.string().required("Please enter your state"),
  address_postal_code: Yup.string().required("Please enter your postal code"),
  address_country: Yup.string().required("Please enter your country"),
  citizenship: Yup.string()
    .nullable()
    .required("Please choose your citizenship"),
});

const CITIZENSHIP_OPTIONS = [
  { label: "US Citizen", value: "US_CITIZEN" },
  { label: "International", value: "INTERNATIONAL" },
];

const AddressPage = () => {
  useWorkflowPosition(5);
  const { offering, user, investment } = useContext(WorkflowContext);

  const dispatchEvent = useEventDispatcher();
  const updateInvestment = useUpdateInvestment(investment?.id);
  const updateUser = useUpdateCurrentUser();
  const { refetch: refetchUser } = useCurrentUser();

  const navigate = useNavigate();
  const toast = useToast();

  const onSubmit = async ({ citizenship, ...address }) => {
    try {
      await Promise.all([
        updateInvestment.mutateAsync({ citizenship }),
        updateUser.mutateAsync(address),
        dispatchEvent.mutateAsync({
          investmentId: investment.id,
          event: "ADDRESS",
          action: "SUBMITTED",
          actor: { user: user.id },
        }),
      ]);

      await refetchUser();

      navigate(`/offerings/${offering.slug}/signers`);
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        description: error.response.data.errors.join(". "),
      });
      return;
    }
  };

  const initialValues = {
    address_street_1: user?.address_street_1,
    address_street_2: user?.address_street_2,
    address_city: user?.address_city,
    address_state: user?.address_state,
    address_postal_code: user?.address_postal_code,
    address_country: user?.address_country,
    citizenship: investment?.citizenship,
  };

  const formik = useFormik({
    validationSchema: addressSchema,
    enableReinitialize: true,
    onSubmit,
    initialValues,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  const handleOnAddressSelect = useCallback(
    (value) => {
      const newValues = {
        ...formik.values,
        address_street_1: value.street,
        address_street_2: value?.street2,
        address_city: value.city,
        address_state: value.state,
        address_postal_code: value.zipcode,
        address_country: value.country,
      };

      formik.setValues(newValues);

      // Trigger validation for all fields
      Object.keys(newValues).forEach((field) => {
        formik.setFieldTouched(field, true, false);
      });

      // Run validation
      formik.validateForm(newValues);
    },
    [formik.values]
  );

  return (
    <WorkflowContainer
      title="Investor Details"
      onContinue={formik.handleSubmit}
      buttonProps={{
        isDisabled: !formik.isValid,
        isLoading: updateUser.isLoading,
      }}
    >
      <Text>Select your citizenship:</Text>
      <RadioGroup
        fieldName="citizenship"
        options={CITIZENSHIP_OPTIONS}
        defaultValue={investment?.citizenship}
        errors={formik.touched.citizenship && formik.errors}
        setFieldValue={formik.setFieldValue}
      />
      <Heading textStyle="h1" mb={0} fontWeight="bold">
        Address
      </Heading>
      <Text>
        Enter your principal address, then confirm it below and edit it if
        necessary. Legal address of investor (individual or entity) as it should
        appear in your investment documents.
      </Text>
      <GooglePlaceSearch onSelected={handleOnAddressSelect} />
      <AddressForm formik={formik} />
    </WorkflowContainer>
  );
};

export default AddressPage;
