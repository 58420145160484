import {
  Box,
  Button,
  Flex,
  Image,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/browser";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  useEmbeddedSignature,
  useStartSignatureRequest,
} from "@equidefi/portals/hooks/useSignatures";
import { useCurrentUser } from "@equidefi/portals/hooks/useUsers";

import { Heading, Paper, Text } from "@equidefi/ui";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { useCurrentInvestment } from "../../../hooks/useInvestments";
import { WorkflowContainer } from "../WorkflowContainer";
import { WorkflowContext } from "../context";

import { CONFIG } from "../../../constants/config";
import dropboxSignImg from "../../../images/dropbox-sign.svg";

const InvestmentAgreements = () => {
  const toast = useToast();
  const [closed, setClosed] = useState(false);
  const { investment, offering, setInvestment, analytics } =
    useContext(WorkflowContext);
  const { data: user } = useCurrentUser();

  const { data, refetch } = useCurrentInvestment(offering?.id, {
    refetchInterval: closed ? 2_500 : false,
  });

  const signature = useEmbeddedSignature({
    testMode: CONFIG.ENV !== "production",
    skipDomainVerification: CONFIG.ENV !== "production",
    onClose: async () => {
      setClosed(true);
      const { data } = await refetch();
      setInvestment(data);
    },
  });
  const startSignatureMut = useStartSignatureRequest();

  useEffect(() => {
    if (data) setInvestment(data);
  }, [data, setInvestment]);

  const navigate = useNavigate();

  useDocumentTitle([
    "Subscription Agreement",
    investment?.offering?.name,
    investment?.issuer?.name,
  ]);

  const signer = useMemo(() => {
    return investment?.investment_agreements?.[0]?.data?.signatures.find(
      (signature) => signature.signerRole === "signer1"
    );
  }, [investment?.investment_agreements]);

  const hasSigned = signer?.statusCode === "signed";

  useEffect(() => {
    if (hasSigned) setClosed(false);
  }, [hasSigned]);

  /**
   * @description When an investor has not started the signing parocess, trigger signature request process
   */
  const handleStartSignature = useCallback(async () => {
    analytics.track("signAgreement");

    let data;
    let investmentAgreementId = investment?.investment_agreements?.at(0)?.id;
    let signatureRequest = investment?.investment_agreements?.at(0)?.data;

    if (!signatureRequest) {
      try {
        data = await startSignatureMut.mutateAsync(investment.id);
      } catch (e) {
        console.error(e);
        Sentry.captureException(e, {
          user: {
            id: user?.id,
            email: user?.email,
          },
        });
        toast({
          status: "error",
          description:
            `Sorry, something went wrong. ${e?.response?.data?.errors?.join(
              ". "
            )}`.trimEnd(),
        });
      }

      signatureRequest = data.signature_request;
      investmentAgreementId = data.investment_agreement_id;

      const _investment = await refetch();
      setInvestment(_investment.data);
    }

    signature.start(
      investmentAgreementId,
      signatureRequest?.signatures.at(0).signatureId
    );
  }, [
    investment?.id,
    investment?.investment_agreements,
    refetch,
    signature,
    startSignatureMut,
    setInvestment,
    analytics,
  ]);

  /** @description Triggers the move to the next (and possibly final?) step */
  const handleContinue = async () => {
    if (offering.exemption?.primary === "Regulation A") {
      analytics.track("complete");
      navigate(`/vault/dashboard`, {
        state: {
          completed: true,
          investment_id: investment.id,
        },
      });
    } else {
      navigate(`/offerings/${offering.slug}/accreditation`);
    }
  };

  return (
    <WorkflowContainer
      title="Agreement"
      onContinue={handleContinue}
      buttonProps={{ isDisabled: !hasSigned }}
    >
      <Text>
        You must sign these subscription agreements to complete your investment.
      </Text>

      <Text>
        You will be able to download these documents once they have been
        countersigned by the issuer.
      </Text>

      {hasSigned ? (
        <Paper variant="primary" p={4} mb={4}>
          <Flex justify="space-between" align="center">
            <Box>
              <Heading as="h3" mb={0} textStyle="h2">
                Subscription Booklet
              </Heading>
              <Text mb={0} fontSize="md" textStyle="context">
                Signed
              </Text>
            </Box>
          </Flex>
        </Paper>
      ) : (
        <Paper p={4} mb={4}>
          <Heading as="h3" mb="0" textStyle="h2">
            Subscription Booklet
          </Heading>
          <Text fontSize="md" textStyle="context">
            Not Signed
          </Text>
          <SimpleGrid columns="1" gap="4" w="full">
            <Button
              flexShrink="0"
              flexGrow="1"
              isLoading={signature.isLoading || startSignatureMut.isLoading}
              onClick={() => handleStartSignature()}
            >
              Sign
            </Button>
          </SimpleGrid>
        </Paper>
      )}
      <Flex align="center" direction="column" justify="center" my={6}>
        <Text mb={1} textStyle="context" textTransform="uppercase">
          Signature Process Powered By
        </Text>
        <Image
          w={{
            base: "136px",
            sm: "190px",
          }}
          src={dropboxSignImg}
          alt="Dropbox Sign"
        />
      </Flex>
    </WorkflowContainer>
  );
};

export default InvestmentAgreements;
