import axios from "axios";
import React from "react";
import { useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useFlags } from "launchdarkly-react-client-sdk";

import "bootstrap-daterangepicker/daterangepicker.css";
import "react-toastify/dist/ReactToastify.css";

import UserToken from "@equidefi/portals/clients/UserToken";

import {
  useCurrentUser,
  useIdentifyLDUser,
} from "@equidefi/portals/hooks/useUsers";
import MyProfile from "./pages/app/vault/profile";
import InvestmentOverview from "./pages/app/vault/InvestmentOverview";
import VaultLayout from "./pages/app/vault/layout";
import Vault from "./pages/app/vault/vault";
import Auth from "./pages/auth";
import ErrorPage from "./pages/ErrorPage";
import Forgot from "./pages/login/forgot";
import Login from "./pages/login/login";
import MFA from "./pages/login/mfa";
import ResetPassword from "./pages/login/reset";
import Workflow from "./pages/workflow";
import WorkflowAccreditation from "./pages/workflow/accreditation";
import AdditionalSigner from "./pages/workflow/AdditionalSigner";
import WorkflowAgreements from "./pages/workflow/agreement";
import WorkflowCompleted from "./pages/workflow/complete";
import WorkflowRootError from "./pages/workflow/error";
import WorkflowKYC from "./pages/workflow/kyc";
import WorkflowSigners from "./pages/workflow/signers";
import WorkflowAddress from "./pages/workflow/address";
import WorkflowSubscription from "./pages/workflow/subscription";
import WorkflowPayment from "./pages/workflow/payment";
import WorkflowPaymentManual from "./pages/workflow/payment/PaymentManual";
import WorkflowPaymentProcessing from "./pages/workflow/payment/PaymentProcessing";
import WorkflowProfile from "./pages/workflow/profile";
import WorkflowRegistration from "./pages/workflow/registration";
import WorkflowRedirect from "./pages/workflow/WorkflowRedirect";
import MaintenanceScreen from "@equidefi/portals/components/MaintenanceScreen";
import WorkflowMFA from "./pages/workflow/registration/MFA";
import SupportPage from "./pages/SupportPage";
import { useIntercomMessenger } from "./hooks/useIntercomMessenger";

const App = (props) => {
  const token = useSelector((state) => state.token);

  const { data: me } = useCurrentUser();
  useIdentifyLDUser(me);
  useIntercomMessenger();
  const flags = useFlags();

  if (UserToken.token()) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${UserToken.token()}`;
  } else {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  if (flags.maintenanceMode) return <MaintenanceScreen />;

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/mfa",
      element: <MFA />,
    },
    {
      path: "/forgot",
      element: <Forgot />,
    },
    {
      path: "/support",
      element: <SupportPage />,
    },
    {
      path: "/reset/:userId/:token",
      element: <ResetPassword />,
    },
    {
      path: "/vault",
      element: <VaultLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          path: "dashboard",
          element: <Vault />,
        },
        {
          path: "investment/:id",
          element: <InvestmentOverview />,
        },
        {
          path: "profile",
          element: <MyProfile />,
        },
      ],
    },
    {
      path: "/auth/:redirectUrl/:investmentId/:token",
      element: <Auth />,
    },
    {
      path: "/offerings/:slug",
      element: <Workflow />,
      errorElement: <WorkflowRootError />,
      children: [
        {
          path: "registration",
          element: <WorkflowRegistration />,
        },
        {
          path: "mfa",
          element: <WorkflowMFA />,
        },
        {
          path: "address",
          element: <WorkflowAddress />,
        },
        {
          path: "signers",
          element: <WorkflowSigners />,
        },
        {
          path: "kyc",
          element: <WorkflowKYC />,
        },
        {
          path: "subscription",
          element: <WorkflowSubscription />,
        },
        {
          path: "payment",
          element: <WorkflowPayment />,
        },
        {
          path: "payment/manual",
          element: <WorkflowPaymentManual />,
        },
        {
          path: "payment/processing",
          element: <WorkflowPaymentProcessing />,
        },
        {
          path: "profile",
          element: <WorkflowProfile />,
        },
        {
          path: "agreement",
          element: <WorkflowAgreements />,
        },
        {
          path: "accreditation",
          element: <WorkflowAccreditation />,
        },
        {
          path: "complete",
          element: <WorkflowCompleted />,
        },
      ],
    },
    {
      path: "/investment/signer/:investmentAgreementId/:signatureId",
      element: <AdditionalSigner />,
    },
    {
      path: "/offering/:offeringId/*",
      element: <WorkflowRedirect />,
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>
  );

  // return (
  //   <div>
  //     <Router>
  //       <QueryParamProvider adapter={ReactRouter5Adapter}>
  //         <Switch>
  //           <LayoutRoute
  //             exact
  //             path="/auth/:redirectUrl/:investmentId/:token"
  //             layout={publicLayout}
  //             component={auth}
  //           />
  //           <LayoutRoute
  //             exact
  //             path="/welcome"
  //             layout={publicLayout}
  //             component={welcome}
  //           />
  //           <LayoutRoute
  //             path="/offering/:offeringId"
  //             layout={publicLayout}
  //             component={OfferingJoin}
  //           />
  //           <LayoutRoute
  //             exact
  //             path="/investor/register"
  //             layout={publicLayout}
  //             component={investorRegister}
  //           />
  //           <PrivateLayoutRoute
  //             exact
  //             path="/investor/questionnaire"
  //             layout={publicLayout}
  //             component={questionnaire}
  //           />
  //           <PrivateLayoutRoute
  //             exact
  //             path="/investor/questionnaire/start"
  //             layout={publicLayout}
  //             component={questionnaireStart}
  //           />
  //           <PrivateLayoutRoute
  //             exact
  //             path="/investor/register/questionnaire"
  //             layout={publicLayout}
  //             component={investorQuestionnaire}
  //           />
  //           <PrivateLayoutRoute
  //             exact
  //             path="/investor/review"
  //             layout={publicLayout}
  //             component={inReview}
  //           />
  //           <LayoutRoute
  //             exact
  //             path="/investor/link-expired"
  //             layout={publicLayout}
  //             component={LinkExpired}
  //           />
  //           <LayoutRoute
  //             exact
  //             path="/offering"
  //             layout={publicLayout}
  //             component={offering}
  //           />
  //           <LayoutRoute
  //             exact
  //             path={["/offerings/:slug"]}
  //             layout={publicLayout}
  //             component={Workflow}
  //           />
  //           <PrivateLayoutRoute
  //             exact
  //             path="/offering/closed"
  //             layout={publicLayout}
  //             component={offeringClosed}
  //           />
  //           <PrivateLayoutRoute
  //             exact
  //             path="/subscription"
  //             layout={publicLayout}
  //             component={subscription}
  //           />
  //           <PrivateLayoutRoute
  //             exact
  //             path="/agreement"
  //             layout={publicLayout}
  //             component={agreement}
  //           />
  //           <PrivateLayoutRoute
  //             exact
  //             path="/payment"
  //             layout={publicLayout}
  //             component={payment}
  //           />
  //           <PrivateLayoutRoute
  //             exact
  //             path="/payment/pending"
  //             layout={publicLayout}
  //             component={paymentPending}
  //           />
  //           <PrivateLayoutRoute
  //             exact
  //             path="/payment/:offeringId/:investmentId/processing"
  //             layout={publicLayout}
  //             component={OpenOfferingPaymentProcessing}
  //           />
  //           <PrivateLayoutRoute
  //             exact
  //             path="/payment/manual"
  //             layout={publicLayout}
  //             component={paymentManual}
  //           />
  //           <LayoutRoute
  //             exact
  //             path="/"
  //             layout={publicLayout}
  //             component={login}
  //           />
  //           <LayoutRoute
  //             exact
  //             path="/mfa"
  //             layout={publicLayout}
  //             component={mfa}
  //           />
  //           <LayoutRoute
  //             exact
  //             path="/forgot"
  //             layout={publicLayout}
  //             component={forgot}
  //           />
  //           <PrivateLayoutRoute
  //             exact
  //             path="/complete-profile"
  //             layout={publicLayout}
  //             component={completeProfile}
  //           />
  //           <LayoutRoute
  //             exact
  //             path="/reset/:id/:token"
  //             layout={publicLayout}
  //             component={reset}
  //           />
  //           <LayoutRoute
  //             exact
  //             path="/user/verify/:token"
  //             layout={publicLayout}
  //             component={VerifyInvestor}
  //           />
  //           <PrivateLayoutRoute
  //             exact
  //             path="/app/vault"
  //             layout={AdminLayout}
  //             component={vault}
  //           />
  //           <PrivateLayoutRoute
  //             exact
  //             path="/app/investment/:id"
  //             layout={AdminLayout}
  //             component={InvestmentOverview}
  //           />
  //           <PrivateLayoutRoute
  //             exact
  //             path="/app/deposit"
  //             layout={AdminLayout}
  //             component={deposit}
  //           />
  //           <PrivateLayoutRoute
  //             exact
  //             path="/app/services"
  //             layout={AdminLayout}
  //             component={services}
  //           />
  //           <PrivateLayoutRoute
  //             exact
  //             path="/app/deals"
  //             layout={AdminLayout}
  //             component={deals}
  //           />
  //           <PrivateLayoutRoute
  //             exact
  //             path="/app/events"
  //             layout={AdminLayout}
  //             component={events}
  //           />
  //           <PrivateLayoutRoute
  //             exact
  //             path="/app/profile"
  //             layout={AdminLayout}
  //             component={profile}
  //           />
  //         </Switch>
  //       </QueryParamProvider>
  //     </Router>
  //
  //     <ToastContainer />
  //   </div>
  // );
};

export default App;
