import { useOpenOffering } from "../../hooks/useOfferings";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

export default function WorkflowRedirect() {
  const { offeringId } = useParams();
  const navigate = useNavigate();
  const { data: offering } = useOpenOffering(offeringId);

  useEffect(() => {
    if (offering) {
      navigate(`/offerings/${offering.slug}`);
    }
  }, [offering]);

  return <></>;
}
