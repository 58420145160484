import {
  Box,
  Center,
  Flex,
  IconButton,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import AWSClient from "@equidefi/portals/clients/AWSClient";
import { usePresignedUrl } from "@equidefi/portals/hooks/useAccreditation";
import { CONTENT_TYPES } from "@equidefi/shared/constants/files";
import { Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";
import React, { useState } from "react";

export const UploadComponent = ({
  investmentId,
  fileNum,
  vaultField,
  handleChange,
  mb = 2,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [isUploaded, setUploaded] = useState(false);
  const [file, setFile] = useState();
  const [fileExtension, setFileExtension] = useState();
  const getPresignedUrl = usePresignedUrl(investmentId);
  const toast = useToast();

  const handleUpload = async () => {
    setLoading(true);
    const response = await getPresignedUrl.mutateAsync({
      vaultField,
      fileExtension,
    });
    const client = new AWSClient();
    client
      .s3Upload(response.signedUrl, file, fileExtension, file.size)
      .then(() => {
        toast({
          status: "success",
          description: "Your file was uploaded successfully!",
        });
        handleChange({
          target: {
            name: vaultField,
            value: response.url,
          },
        });
        setUploaded(true);
      })
      .catch((e) => {
        console.log(e);
        toast({
          status: "error",
          description: "Your file failed to upload!",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const Action = () => {
    return (
      <Tooltip label="Upload file" aria-label="Upload file" hasArrow>
        <IconButton
          isDisabled={!file}
          background="primary"
          isLoading={isLoading}
          onClick={(e) => {
            e.preventDefault();
            handleUpload();
          }}
          minW="12"
          h="12"
          colorScheme="black"
          icon={<Icon.Upload size="1em" />}
          aria-label="Upload file"
        />
      </Tooltip>
    );
  };

  if (isUploaded) {
    return (
      <Center
        key={fileNum}
        background="equidefi.green"
        borderRadius="8px"
        color="white"
        w="full"
        height="12"
        mb={mb}
      >
        <Icon.Lock size="1em" />
        <Text mb="0" ml="1">
          Secure Upload Complete
        </Text>
      </Center>
    );
  }

  return (
    <Flex key={fileNum} minW="full" gap="1" mb={mb}>
      <Box
        as="input"
        type="file"
        onChange={(event) => {
          if (!event.target.files[0]) {
            return; // exit, no file selected
          }
          const ext = event.target.files[0].name.split(".").pop();
          if (!Object.keys(CONTENT_TYPES).includes(ext)) {
            toast({
              type: "error",
              description: `You cannot upload a ${ext} file type, please upload one of these supported types: ${Object.keys(
                CONTENT_TYPES
              ).join(", ")}`,
            });
            return;
          }
          setFile(event.target.files[0]);
          setFileExtension(ext);
        }}
        bg="#dfd9ea"
        w="full"
        pt="8px"
        pl="8px"
        borderRadius="lg"
        m={0}
        border="1px dashed #7283a0"
      />
      <Action />
    </Flex>
  );
};
