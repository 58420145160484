import {
  Alert,
  IconButton,
  useDisclosure,
  useToast,
  useToken,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { CardList, CardListItem, Heading, Text } from "@equidefi/ui";
import { Icon } from "@equidefi/ui/icon";
import RadioGroup from "@equidefi/ui/forms/RadioGroup";
import { Input } from "@equidefi/ui/forms";
import { MAX_ADDITIONAL_SIGNERS } from "@equidefi/shared/constants/investments";
import { ENTITY_TYPE_OPTIONS } from "@equidefi/shared/constants/profile";

import useWorkflowPosition from "../../../hooks/useWorkflowPosition";
import { WorkflowContainer } from "../WorkflowContainer";
import WorkflowForm from "../WorkflowForm";
import { WorkflowContext } from "../context";
import { SignerModal } from "@equidefi/portals/components/SignerModal";
import { useInvestmentSigners } from "@equidefi/portals/hooks/useSigners";
import { useStartSignatureRequest } from "@equidefi/portals/hooks/useSignatures";
import { useUpdateCurrentUser } from "@equidefi/portals/hooks/useUsers";
import { MESSAGE } from "../../../../../issuer/src/constants/forms";

const schema = Yup.object().shape({
  company: Yup.string()
    .nullable()
    .when("entity_type", {
      is: "Entity",
      then: Yup.string().trim().required(MESSAGE.REQUIRED("Entity Name")),
    }),
  entity_type: Yup.string().nullable().required("Please select an entity type"),
});

const helperText = {
  INDIVIDUAL: {
    text: "Select this option for Individual, Joint Tenants, Custodian/Minor",
    header: "Individual or Joint Investors",
  },
  ENTITY: {
    text: "An entity may be a Trust, Corporation, Partnership, LLC, etc…",
    header: "Entity",
  },
};

const DEFAULT_DATA = {
  company: null,
  entity_type: "INDIVIDUAL",
};

const Signers = () => {
  const { investment, offering, user } = useContext(WorkflowContext);
  useWorkflowPosition(5);
  const [equidefiRed, equidefiBlue] = useToken("colors", [
    "equidefi.red",
    "equidefi.blue",
  ]);
  const toast = useToast();
  const [spinner, showSpinner] = useState(false);
  const [isDeletingSigners, setIsDeletingSigners] = useState(false);
  const navigate = useNavigate();

  const update = useUpdateCurrentUser();
  const signatureRequest = useStartSignatureRequest();
  const modal = useDisclosure();

  const onSubmit = async (data) => {
    showSpinner(true);
    try {
      await update.mutateAsync({
        company: data.company?.trim(),
        entity_type: data.entity_type,
      });

      await signatureRequest.mutateAsync(investment.id);
      navigate(`/offerings/${offering.slug}/kyc`);
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        description:
          error.response?.data?.errors?.join(". ") ?? "Something went wrong",
      });
    } finally {
      showSpinner(false);
    }
  };

  const {
    handleSubmit,
    setFieldValue,
    getFieldProps,
    values,
    errors,
    touched,
    isValid,
  } = useFormik({
    initialValues: user || DEFAULT_DATA,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
  });

  const {
    signers,
    onEdit,
    onCreate,
    onDelete,
    onDeleteMultipleSigners,
    onSubmit: onSubmitModal,
    initialValues,
  } = useInvestmentSigners(investment.id, user?.entity_type, {
    onSuccess: () => {
      modal.onClose();
    },
  });

  const canAddMoreSigners = useMemo(
    () => signers.length < MAX_ADDITIONAL_SIGNERS[values.entity_type],
    [signers.length, values.entity_type]
  );

  const updateForIndividual = useCallback(async () => {
    if (isDeletingSigners) return;

    setFieldValue("company", null);

    const maxIndividualSigners = MAX_ADDITIONAL_SIGNERS["INDIVIDUAL"];
    if (signers.length <= maxIndividualSigners) return;

    const excessSignerIds = signers
      .slice(maxIndividualSigners)
      .map((signer) => signer?.signing_party_id)
      .filter(Boolean);

    if (excessSignerIds.length > 0) {
      try {
        setIsDeletingSigners(true);
        await onDeleteMultipleSigners(excessSignerIds);
      } catch (error) {
        console.error("Failed to delete excess signers:", error);
      } finally {
        setIsDeletingSigners(false);
      }
    }
  }, [signers, isDeletingSigners]);

  useEffect(() => {
    if (values?.entity_type !== "INDIVIDUAL") return;

    updateForIndividual();
  }, [values.entity_type]);

  return (
    <WorkflowContainer
      title="Signers"
      onContinue={handleSubmit}
      buttonLabel={signers.length > 0 ? "Continue" : "Skip"}
      buttonProps={{
        isDisabled: values?.entity_type === "ENTITY" && !isValid,
        isLoading: spinner,
      }}
    >
      <WorkflowForm onSubmit={handleSubmit}>
        <Text>Are you investing as an individual or an entity?</Text>
        <RadioGroup
          fieldName="entity_type"
          defaultValue="INDIVIDUAL"
          options={ENTITY_TYPE_OPTIONS}
          errors={touched.entity_type && errors}
          setFieldValue={setFieldValue}
        />
        {values.entity_type ? (
          <Alert
            status="info"
            flexDirection="column"
            borderRadius="8"
            border="1px solid"
            borderColor="equidefi.blue"
            mb="8"
            alignItems="start"
            padding="4"
          >
            <Heading fontWeight="bold" m="0" textStyle="h3">
              {helperText[values.entity_type].header}
            </Heading>
            <Text m="0">{helperText[values.entity_type].text}</Text>
          </Alert>
        ) : null}

        {values?.entity_type === "ENTITY" && (
          <Input
            label="Entity Name"
            placeholder="e.g. Acme Corporation"
            error={touched.company && errors.company}
            isInvalid={errors.company && touched.company}
            {...getFieldProps("company")}
          />
        )}
      </WorkflowForm>
      <Text m="0">Add any required additional signers</Text>
      <CardList my="4">
        <CardListItem>
          <Heading my="2">{`${user?.first_name} ${user?.last_name}`}</Heading>
          <Text textStyle="context" m="0">
            {user?.email}
          </Text>
          <Text textStyle="context" mb="2">
            {user?.phone}
          </Text>
        </CardListItem>
        {signers.map((value, i) => (
          <CardListItem
            key={value?.id || i}
            action={
              <IconButton
                onClick={() => onDelete(value?.signing_party_id)}
                icon={<Icon.Trash size="1.5em" color={equidefiRed} />}
              />
            }
            secondaryAction={
              <IconButton
                onClick={() => {
                  onEdit(value);
                  modal.onOpen();
                }}
                icon={<Icon.Edit size="1.5em" color={equidefiBlue} />}
              />
            }
          >
            <Heading my="2">{`${value.first_name} ${value.last_name}`}</Heading>
            <Text textStyle="context" m="0">
              {value.email}
            </Text>
            <Text textStyle="context" mb="2">
              {value.phone}
            </Text>
          </CardListItem>
        ))}
        {canAddMoreSigners && (
          <CardListItem
            key="add"
            onClick={() => {
              onCreate();
              modal.onOpen();
            }}
            action={<IconButton icon={<Icon.Plus size="1.5em" />} />}
          >
            <Text textStyle="context" my="2">
              Add another signer
            </Text>
          </CardListItem>
        )}
      </CardList>

      <SignerModal
        isOpen={modal.isOpen}
        initialValues={initialValues}
        onHide={modal.onClose}
        onSubmit={onSubmitModal}
      />
    </WorkflowContainer>
  );
};

export default Signers;
