import { Card, Image, VStack } from "@chakra-ui/react";
import EquidefiLogo from "../../../images/logo-white.png";
import BackgroundLogo from "../../../images/equidefi-logo-mask.svg";
import { Text } from "@equidefi/ui";
import { CurrencyFormatter } from "../../../constants/format";

const InvestorBanner = ({ user, investments }) => {
  const amount = investments.reduce(
    (acc, investment) => acc + Number(investment?.amount),
    0
  );

  return (
    <Card
      bg="linear-gradient(-45deg, #3c0c94 0%, #0e1dff 40%, #3c0c94 100%)"
      h="auto"
      borderRadius="8px"
      maxW="full"
    >
      <VStack
        bgImage={BackgroundLogo}
        w="full"
        h="full"
        p="5"
        justify="space-between"
        align="start"
      >
        <Image src={EquidefiLogo} alt="EquiDeFi" maxH="42px" />

        <VStack alignSelf="end" align="end" spacing="0">
          <Text fontWeight="bold" fontSize="26px" color="white" m="0">
            {CurrencyFormatter.format(amount)}
          </Text>
          <Text color="white" m="0">
            {`in ${investments.length} investments`}
          </Text>
        </VStack>
        <Text
          color="white"
          opacity="70%"
          letterSpacing="2px"
          textTransform="uppercase"
          m="0"
        >
          {user?.first_name &&
            user?.last_name &&
            `${user.first_name} ${user.last_name}`}
        </Text>
      </VStack>
    </Card>
  );
};

export default InvestorBanner;
